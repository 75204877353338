import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Pet Store Website Template | Uizard"
    category="Website Templates"
    url="/templates/website-templates/pet-store/"
    metaDescription="Our Pet Store template is designed to impress. Showcasing all important product details in a simplistic format, it's perfect for pet stores of all sizes."
    description="
    h2:A pet store website design template to transform your design flow
    <br/>
    Introducing our website design template for pet stores. This pet store template has everything you need for your own pet store website design and is sure to streamline your design project. Sign up to Uizard today and make our design templates your secret weapon for <a:https://uizard.io/wireframing/>UI wireframing</a>, mockups, and prototyping.
    <br/>
    h3:A pet shop website design template that really sings!
    <br/>
    Showcasing all important product details in a simplistic format, our pet store template is perfect for pet stores of all shapes and sizes. As with all our <a:https://uizard.io/templates/website-templates/>website design templates</a>, this design comes with all the must-have screens and UX considerations built in. Just apply your brand colors and make content changes to suit your business, and you'll be ready to rock and roll.
    <br/>
    h3:Unleash your inner designer alone or with your team
    <br/>
    Uizard is built for collaboration, meaning you can work solo to bring your pet store design to life, or you can share your project with your teammates for instant collaboration. Realizing your ideas on a screen in front of you has never been so easy, join the magical design revolution with Uizard today.
    "
    pages={[
      "A product page",
      "All necessary shopping cart pages",
      "A product categories page",
      "An order summary page",
      "A blog page",
      "And more!",
    ]}
    projectCode="oa1jA8yrmwfo5gMzjG5w"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of online pet store: home page"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of online pet store: product categories"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of online pet store: item details"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of online pet store: shopping cart"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of online pet store template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/pet-store/Pet-Store-Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/pet-store/Pet-Store-Categories.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/pet-store/Pet-Store-Item-Detail.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/pet-store/Pet-Store-Cart.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/pet-store/Pet-Store-Summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
